.display-flex {
  display: flex !important;
}

.display-grid {
  display: grid !important;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.flex-column {
  flex-direction: column !important;
}

.display-none {
  display: none !important;
}

.align-items-start {
  align-items: start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.align-self-stretch {
  align-self: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-end {
  align-items: end !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-m5 {
  margin-bottom: -5px;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-auto {
  flex: auto;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-2-16 {
  flex: 2 16px;
}

.flex-70 {
  flex: 70%;
}

.flex-40 {
  flex: 40%;
}

.flex-30 {
  flex: 30% 0 0;
}

.flex-20 {
  flex: 20% 0 0;
}

.flex-10 {
  flex: 10% 0 0;
}

.flex-120 {
  flex: 120px 0 0;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 4px;
}

.p-0 {
  padding: 0 !important;
}

.p-8 {
  padding: 8px !important;
}

.py-6 {
  padding: 6px 0 !important;
}

.py-16 {
  padding: 16px 0 !important;
}

.px-16 {
  padding: 0 16px !important;
}

.pl-8 {
  padding-left: 8px;
}

.pl-20 {
  padding-left: 20px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pb-20 {
  padding-bottom: 20px;
}
