@import 'variables';
@import './fonts.mixins';

.dropdown-value {
  @include dropdownValue;
}

.pre-title {
  @include preTitle;
}

.pre-option {
  @include preOption;
}

.pre-text {
  @include preLabel;
}

.cell-editable-text {
  @include cellEditableText;
}

.fs {
  &.s10 {
    font-size: 10px;
  }

  &.s14 {
    font-size: 14px;
  }

  &.s16 {
    font-size: 16px;
  }

  &.s18 {
    font-size: 18px;
  }

  &.s20 {
    font-size: 20px;
  }

  &.s24 {
    font-size: 24px !important;
  }

  &.s26 {
    font-size: 26px;
  }

  &.s28 {
    font-size: 28px;
  }

  &.s30 {
    font-size: 30px !important;
  }

  &.s32 {
    font-size: 32px !important;
  }
}

.pre-label,
label {
  font-weight: 700;
  font-size: 14px;

  &.tpp-label-13 {
    font-size: 13pt;
    padding: 5px;
  }

  &.tpp-label-14 {
    font-size: 14pt;
    color: $tpp-blue-med;
  }

  &.tpp-label-16 {
    font-size: 16pt;
    color: map-get($map: $mat-myapp-primary, $key: 400);
    margin-right: 15px;
  }
}

.lowercase {
  text-transform: lowercase;
}

h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 33.6px;
  margin: 0;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;

  &.italic {
    font-style: italic;
  }

  &.bold {
    font-weight: 700;
  }
}

.caps-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 140%;
}

.body {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.uppercase {
  text-transform: uppercase;
}

.body-small {
  @include bodySmall;
}

.body-small-italic {
  @include bodySmall;

  font-style: italic;
}

.body-small-bold {
  @include bodySmallBold;
}

.body-small-bold-italic {
  @include bodySmallBold;

  font-style: italic;
}

.body-bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.fs-26 {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.body-description {
  @include bodyDescription;
}

.placeholder {
  @include customPlaceholder;
}

.link {
  font-size: 16px;
  font-weight: 500;

  &.primary {
    color: $tpp-green2;
  }

  &.accent {
    color: $tpp-green;
  }
}

.font-italic {
  font-style: italic;
}

.small-label-caps {
  text-transform: uppercase;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  &.bold {
    font-weight: 700;
  }
}

.text-wrap {
  word-wrap: break-word;
}

.pre-wrap {
  white-space: pre-wrap;
}

.break-word {
  word-break: break-word;
}

.input-error-container {
  margin: 7px 0 0 12px;
  color: $tpp-input-border-error;

  @include preTitle;
}
